<template lang="pug">
  .wrapper.policy.terms
    h2 {{ $t('legalDocs.termsOfUseTitle') }}
    .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
    .content(v-html="$t('legalDocs.termsOfUseAllInOne')")
</template>

<script>
import { updatedLegal } from '@/dates';

export default {
  data() {
    return { updatedAt: '2022-06-13' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep ol {
  padding-left: 40px !important;
  counter-reset: unset !important;
}
</style>
